<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('concubine.concubine')" />
		<div class="iframeBox" id="iframeBox">
			<iframe
				src="https://henhocungtoi.cc/"
				frameborder="0"
				scrolling="auto"
				id="bi_iframe"
				@load="adjustIframe"
				style="margin-top: -100px;"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
		};
	},
	methods: {
		adjustIframe() {
			let ifm = document.getElementById('bi_iframe')
			ifm.height = document.documentElement.clientHeight
			ifm.width = document.documentElement.clientWidth
		}
	},
	created() {
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #f487e0,#988fba);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
</style>
